// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-author-page-tsx": () => import("./../../../src/components/authorPage.tsx" /* webpackChunkName: "component---src-components-author-page-tsx" */),
  "component---src-components-blog-post-tsx": () => import("./../../../src/components/blogPost.tsx" /* webpackChunkName: "component---src-components-blog-post-tsx" */),
  "component---src-components-category-page-tsx": () => import("./../../../src/components/categoryPage.tsx" /* webpackChunkName: "component---src-components-category-page-tsx" */),
  "component---src-components-custom-page-tsx": () => import("./../../../src/components/customPage.tsx" /* webpackChunkName: "component---src-components-custom-page-tsx" */),
  "component---src-components-event-page-tsx": () => import("./../../../src/components/eventPage.tsx" /* webpackChunkName: "component---src-components-event-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-issues-tsx": () => import("./../../../src/pages/issues.tsx" /* webpackChunkName: "component---src-pages-issues-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-pay-reparations-tsx": () => import("./../../../src/pages/pay-reparations.tsx" /* webpackChunkName: "component---src-pages-pay-reparations-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../../../src/pages/volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */)
}

